button.theme-btn {
  border: none;
  border-radius: 8px;
  min-width: 140px;
  font-size: 14px;
  font-family: poppins, medium, sans-serif;
  font-weight: 300;
  padding: 10px 10px;
  letter-spacing: 0px;
  position: relative;
  outline: none;
  &.btn-block {
    width: 100%;
  }
&.user-white{
  color: #205AC5;
  background-color: #fff;
  width: 122px;
  height: 42px;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 12px;
}
&.add-btn{
  width: 170px;
}
  &.orange {
    color: #fff !important;
    background: #F09030;
    border-radius: 6px;
    height: 48px;
    outline: none;
    font-size: 16px;
  }

  &.white {
    color: #424242 !important;
    background-color: #fff !important;
  }

  &.blue {
    color: #fff !important;
    background-color: #6200ee !important;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.border-yellow {
    width: 170px;
    height: 48px;
    border: 1px solid #FD8A2A;
    background-color: transparent;
    color: #FD8A2A;
    outline: none;
    font-weight: 400    ;
  }

  &.userBtn {
    background-color: #fff;
    box-shadow: 0px 5px 32px #0000000D;
    border-radius: 8px;
    color: #FE7815;
    height: 48px;
    outline: none;
    min-width: 110px;
  }

  .mat-progress-spinner {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
