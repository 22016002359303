.font-6 {
  font-size: 6px;
}
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-25 {
  font-size: 25px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-40 {
  font-size: 40px;
}
.font-42 {
  font-size: 42px;
}

// font-weight
.fw-2 {
  font-weight: 200 !important;
}
.fw-3 {
  font-weight: 300 !important;
}
.fw-4 {
  font-weight: 400 !important;
}
.fw-5 {
  font-weight: 500 !important;
}
.fw-6 {
  font-weight: 600 !important;
}
.fw-7 {
  font-weight: 700 !important;
}
.fw-8 {
  font-weight: 800 !important;
}

.opacity-01 {
  opacity: 0.1;
}
.opacity-02 {
  opacity: 0.2;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-06 {
  opacity: 0.6;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-08 {
  opacity: 0.8;
}
.opacity-09 {
  opacity: 0.9;
}
.opacity-1 {
  opacity: 1;
}

.line-height-05 {
  line-height: 0.5;
}
.line-height-06 {
  line-height: 0.6;
}
.line-height-07 {
  line-height: 0.7;
}

.line-height-1 {
  line-height: 1;
}
.line-height-11 {
  line-height: 1.1;
}
.line-height-12 {
  line-height: 1.2;
}
.line-height-13 {
  line-height: 1.3;
}
.line-height-14 {
  line-height: 1.4;
}
.line-height-15 {
  line-height: 1.5;
}
.line-height-16 {
  line-height: 1.6;
}

.letter-space {
 letter-spacing: 0;
}

.roboto {
  font-family: "Roboto", sans-serif !important;
  padding: 0 20px 14px 20px;
}
