// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "./style/fonts.scss";
@import "./style/color.scss";
@import "./style/buttons.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$admin-setup-primary: mat.define-palette(mat.$indigo-palette);
$admin-setup-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-setup-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-setup-theme: mat.define-light-theme(
  (
    color: (
      primary: $admin-setup-primary,
      accent: $admin-setup-accent,
      warn: $admin-setup-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($admin-setup-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.3 !important;
  letter-spacing: 0.5px !important;
  font-weight: 400;
  // font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #9b9fb7;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #00264b;
  border-radius: 5px;
}

.cursor {
  cursor: pointer;
}
.mat-icon {
  width: auto !important;
  height: auto !important;
}
ng-otp-input.otpInput:nth-child(5) {
  margin-right: 0px !important;
}

label {
  // margin-left: 1px;
  font-weight: 400;
  color: #3a2f3c;
}

.form-control:focus {
  background-color: #efefef !important;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

// card and table
.card-white {
  background: #ffffff;
  box-shadow: 0px 3px 6px #3a2f3c1f !important;
  border-radius: 8px;
  flex: 1;
  overflow: auto;
}

.table-wraper {
  background-color: #fff;
  padding: 2px 12px 0px 20px;
  border: none;
  border-radius: 10px;

  table.table1 {
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 10px;

    tr {
      padding: 10px;
      border: 1px solid #eaeaea;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      white-space: nowrap;

      &:nth-child(2n) {
        background: #ebebeb !important;
      }

      th {
        border: 1px solid #eaeaea;
        padding: 18px 18px;
        border-radius: 3px;
        font-weight: 600;
        color: #474849;
        font-size: 13px;
      }

      td {
        padding: 18px 18px;
        white-space: normal;

        &.borderAdd {
          border: 1px solid #eaeaea;
        }

        &:last-child {
          padding: 10px 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 500;
          vertical-align: middle !important;
          border: 1px solid #eaeaea;
          font-size: 13px;
          color: #313232;
          display: table-cell; // new add

          a {
            &:hover {
              color: #313232;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

// mat-menu
.mat-menu-item {
  height: 38px !important;
  line-height: 30px !important;
  border: none !important;
  outline: none !important;
}

.mat-menu-panel {
  border-radius: 10px !important;
}

.searchImg {
  img.searchIcon {
    position: absolute;
    top: 14px;
    left: 28px;
  }

  input.inputField {
    padding: 12px 14px 14px 40px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000015;
    border-radius: 10px;
    border: 0;
    outline: none;
    min-width: 400px;

    &::placeholder {
      color: #a3a3a4 !important;
    }
  }
}

::placeholder {
  color: #a3a3a4 !important;
}

.theme-btn {
  border-radius: 5px;
  min-width: 170px !important;
  padding: 9px 16px;
  border: none;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  outline: none;
  color: #4a4a4a;
  font-weight: 500;

  &.base-color {
    background-color: #f09030;
    color: #fff !important;
    border: 1px solid #f09030 !important;
    outline: 0;
    padding: 12px 10px;

    .mat-spinner circle {
      stroke: #fff;
    }
  }

  &.yellow-border {
    border: 1px solid #f09030 !important;
    outline: 0;
  }

  &.white-color {
    background-color: #fff;
    color: #f09030 !important;

    .mat-spinner circle {
      stroke: #f09030;
    }
  }

  .mat-progress-spinner {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

// mat-spinner
.mat-spinner circle {
  stroke: #f09030;
}

// Users Tabs

.tabs-system {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px 18px;
  padding-bottom: 0;

  .btn-group {
    .btn-group-item {
      cursor: pointer;
      font-size: 14px;
      outline: none;
      color: #838383;
      font-weight: 600;
      border: none;
      border-bottom: 3px solid transparent;
      padding: 0px 30px;
      background: transparent;
      padding-top: 2px;
      text-transform: uppercase;
    }

    .active {
      color: #f09030;
      border-bottom: 3px solid #f09030;
      padding: 0px 30px;
      font-weight: 600;
      padding-top: 2px;
      line-height: 1.5;
    }
  }
}

.active-green-btn {
  button {
    background: rgba(24, 166, 39, 0.2);
    color: #18a627;
    border-radius: 3px;
    min-width: 130px;
    max-width: 130px;
    height: 36px !important;
  }
}

.userBreadcums {
  div {
    &:first-child {
      color: #a7a7a7;
    }
  }

  .breadcumsColor {
    color: #a7a7a7;
    padding-left: 8px;
  }

  img {
    width: 14px;
    margin-bottom: 3px;
  }
}

.statusActive {
  color: #20c05b;
  font-weight: bold;
  text-align: right !important;
  position: relative !important;
  line-height: 1 !important;
  height: auto !important;
  margin-top: 10px;
  font-size: 15px;

  &:hover {
    background-color: transparent !important;
  }
}

.iconActive {
  // position: absolute;
  width: 8px;
  height: 8px;
  background-color: #20c05b;
  right: 60px;
  border-radius: 50%;
  left: 30px;
  top: 23px;
  margin-left: 3px;
  &.inactive {
    background: #ae3131;
  }
}

.userImg {
  height: 121px;
  width: 121px;
  border-radius: 50%;
  border: 2px solid #f09030;
}

page-content {
  position: relative;

  .plusimg {
    position: fixed;
    bottom: 18px;
    right: 70px !important;
    cursor: pointer;
    background: #f09030 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 17px #177a4a33;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 35px;
      color: #fff;
    }

    img {
      width: 57px;
    }
  }
}

.userDetails.active {
  padding: 0px 20px !important;
}

.inline-table {
  display: inline-table;
}

.mat-select {
  padding-left: 5px !important;
  font-size: 14px !important;
  // width: 280px !important;
}

.mat-select-placeholder {
  color: #a3a3a4 !important;
  font-size: 14px !important;
}

.mat-select {
  background: #efefef 0% 0% no-repeat padding-box !important;
  border: 0 !important;
  line-height: 1.8 !important;
  box-shadow: none !important;
}

.mat-select-arrow {
  width: auto !important;
  height: auto !important;
  border-top: 0 !important;
}

::placeholder {
  color: #a3a3a4;
  font-size: 14px;
}

.Constructions {
  position: relative;
  // display: inline-block; // remove
  border: 0 !important;
  // width: 100%; //remove
  opacity: 1;
  cursor: pointer;
  .ConstBusiness {
    visibility: hidden;
    min-width: 200px;
    background-color: #fff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 15px;
    position: absolute;
    z-index: 1;
    // top: -58%;
    // left: 80%;
    top: 20%;
    left: 60%;
    margin-left: 0px;
    opacity: 0;
    transition: opacity 1s;

    &::after {
      content: "";
      position: absolute;
      // top: 46%;
      top: 10%;
      left: -7%;
      margin-top: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      transform: rotate(182deg);
    }
  }

  &:hover {
    .ConstBusiness {
      visibility: visible;
      opacity: 1;
      box-shadow: 0px 10px 20px #00000026;
    }
  }
}

.Construction {
  position: relative;
  display: table-cell;
  border: 1px solid #eaeaea !important;
  width: 100%;
  opacity: 1;
  cursor: pointer;

  .ConstBusiness {
    visibility: hidden;
    min-width: 200px;
    background-color: #fff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 15px;
    position: absolute;
    z-index: 1;
    top: -26%;
    left: 61%;
    margin-left: 0px;
    opacity: 0;
    transition: opacity 1s;

    &::after {
      content: "";
      position: absolute;
      top: 46%;
      left: -7%;
      margin-top: -5px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      transform: rotate(182deg);
    }

    .userImg {
      max-width: 40px;
      border-radius: 50%;
      margin-right: 5px;
      height: 40px;
    }
  }

  &:hover {
    .ConstBusiness {
      visibility: visible;
      opacity: 1;
      box-shadow: 0px 10px 20px #00000026;
    }
  }
}

.tabs {
  padding: 10px 0;
}

.userDetailInfo {
  margin-top: 20px;

  .tabs {
    padding-left: 48px;
    padding-top: 35px;
  }
}

.userDetailData {
  margin-top: 32px 6px 6px 6px;

  > div {
    width: 25%;

    > div:nth-of-type(1) {
      margin-bottom: 12px;
      margin-top: 4px;
    }
  }
}

.uploadImg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 8px;
  padding-bottom: 12px;
  width: 100%;
  max-width: 338px;
}

.uploadBorder {
  border: 2px dashed #f09030;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  .addPlus {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px dashed #f09030;
  }
  .mat-icon {
    color: #f09030;
  }
}
.mainTitle {
  padding-top: 30px;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1;
}

.hr-border {
  border-bottom: 2px solid #f5f5f5;
  width: 100%;
  margin: 12px 0;
}

img.adminicon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #f09030;
}

// uploader image
.uploader-img {
  display: flex;
  justify-content: center;
  // margin: 8px 0;
  position: relative;

  .main-img {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    position: relative;
    .imgUrl {
      object-fit: cover;
      width: 150px;
      height: 150px;
      border-radius: 4px;
      position: relative;
    }
  }
  .cross {
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

@media only screen and (min-width: 1920px) {
  page-content {
    padding: 40px !important;
  }

  .table-wraper table.table1 tr th {
    font-size: 18px;
    padding: 30px;
  }

  .table-wraper table.table1 tr th img {
    max-width: 14px;
    min-width: 14px;
  }

  .table-wraper table.table1 tbody tr td {
    font-size: 18px;
    padding: 30px;
  }

  .userBreadcums {
    padding-top: 16px;
    padding-left: 16px;
  }

  .jobData,
  .jobDatas {
    min-height: 460px;
  }

  .iconActive {
    left: 48px !important;
    top: 38px !important;
  }

  .card-white {
    margin-top: 10px;
    // height: 100%;

    .tabs {
      > div {
        margin-top: 24px !important;
      }
    }
  }

  .mainTitle {
    font-size: 32px !important;
    padding-top: 0;
  }

  .tabs-system .btn-group .btn-group-item {
    font-size: 20px;
  }

  .searchImg input.inputField {
    padding: 22px 14px 22px 52px !important;
    min-width: 500px;
  }

  ::placeholder {
    font-size: 18px;
  }

  .active-green-btn button {
    min-width: 190px;
    max-width: 190px;
    height: 56px !important;
    margin-left: 12px;
    font-size: 20px;
  }

  .searchImg img.searchIcon {
    top: 17px;
    width: 27px;
  }

  .filter {
    div {
      &:nth-of-type(1) {
        img {
          min-width: 24px;
        }
      }
    }
  }

  .pagination {
    span {
      font-size: 20px !important;
    }

    li.page-item {
      font-size: 22px;
    }
  }

  .detailClient_card {
    height: 100% !important;
  }
}

.statusCurrent {
  width: 32% !important;
  margin-top: 18px;
}

.status-btn {
  border-radius: 3px;
  min-width: 130px;
  padding: 10px 10px;
  text-align: center;
  &.active {
    background: rgba(24, 166, 39, 0.2);
    color: #18a627;
  }
  &.inactive {
    background: #db303054;
    color: #ae3131;
  }
  &.pending {
    background: rgb(73 73 73 / 10%);
    color: #494949;
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f09030 !important;
}
.mat-select-arrow-wrapper,
.mat-select-arrow-wrapper {
  display: none !important;
}
.mat-select-disabled .mat-select-value {
  color: #222b45;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  &.active {
    color: #20c05b;
    background: #20c05b;
  }
  &.inactive {
    color: #ae3131;
    background: #ae3131;
  }
  &.pending {
    color: #494949;
    background: #494949;
  }
}
.status {
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &.active {
    color: #20c05b;
    .dot {
      background: #20c05b;
    }
  }
  &.pending {
    color: #ae3131;
  }
  &.upcoming {
    color: #20c05b;
  }
  &.available {
    color: #20c05b;
    .dot {
      background: #20c05b;
    }
  }
  &.inactive {
    color: #ae3131;
    .dot {
      background: #ae3131;
    }
  }
  &.pending {
    color: #494949;
    .dot {
      background: #494949;
    }
  }
}

.text-break {
  word-break: break-word;
}

.mat-select-panel-wrap {
  margin-top: 30px;
}

.mat-dialog-container {
  border-radius: 20px !important;
}
