$black: #000000;
$black-m: #00000A;
$black-n: #313232;
$black-b: #3A2F3C;
$black-l: #505050;
$lightBlack: #424242;
$blue: #222b45;
$white: #fff;
$lightBlue: #3c4858;
$grey1: #eee;
$grey2: #a9afbb;
$orange: #fd394b;
$grey3: #F5F5F5;
$grey5:#A3A3A4;
$red: #E81616;
$grey4: #8A8A8A;
$yellow: #F09030;
$l-white:rgba(255, 255, 255, .6);

.l-white{
  color: $l-white;
}

.white{
  color: $white;
}

.yellow{
  color: $yellow;
}

.red{
  color: $red;
}

.black {
  color: $black;
}
.black-m {
  color: $black-m;
}
.black-n {
  color: $black-n;
}
.black-b {
  color: $black-b;
}
.black-l {
  color: $black-l;
}
.lightBlack {
  color: $lightBlack;
}
.blue {
  color: #222b45;
}
.white {
  color: $white;
}
.lightBlue {
  color: $lightBlue;
}
.grey1 {
  color: $grey1;
}
.grey2 {
  color: $grey2;
}
.grey3{
  color: $grey3;
}
.grey4{
  color: $grey4;
}
.grey5{
  color: $grey5;
}
.orange {
    color: $orange;
}
